<script setup>
import AuthLayout from "../../Layouts/AuthLayout.vue";
import {Head} from "@inertiajs/vue3";
import {useForm} from "laravel-precognition-vue-inertia";
import InputError from "../../Components/Forms/InputError.vue";
import ButtonProcess from "../../Components/Forms/ButtonProcess.vue";

const form = useForm("post", route("login"), {
    email: '',
    password: '',
    remember: false,
    processing: false,
});

const submit = () => {
    form.submit({
        onBefore: () => {
            form.processing = true;
            form.errors = {};
        },
        onSuccess: () => {
            form.processing = false;
        },
        onError: () => {
            form.processing = false;
        },
        preserveScroll: true,
    });
}
</script>

<template>
    <Head title="Inicio de sesión"/>

    <AuthLayout>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">Login</h2>
        </template>
        <div class="flex min-h-full flex-1 flex-col justify-center py-36 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="mx-auto h-10 w-auto" src="/images/svg/logo_pai_suscripciones.svg" alt="Pai"/>
                <h2 class="mt-6 text-center text-3xl font-bold leading-9 tracking-tight text-blue-950">Inicio de sesión</h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <form @submit.prevent="submit" class="space-y-6">
                        <div>
                            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Correo electrónico</label>
                            <div class="mt-2">
                                <input v-model="form.email" id="email" name="email" type="email" autocomplete="email"
                                    required=""
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <InputError class="mt-1" :message="form.errors.email" />
                            </div>
                        </div>

                        <div>
                            <label for="password"
                                class="block text-sm font-medium leading-6 text-gray-900">Contraseña</label>
                            <div class="mt-2">
                                <input v-model="form.password" id="password" name="password" type="password"
                                    autocomplete="current-password" required=""
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <InputError class="mt-1" :message="form.errors.password" />
                            </div>
                        </div>

                        <!--
                        <div class="flex items-center justify-between">

                            <div class="text-sm leading-6">
                                <a :href="route('password.request')" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
                            </div>
                        </div>
                        -->
                        <ButtonProcess
                            type="submit"
                            classes="w-full"
                            :text="'Entrar'"
                            :textProcessing="'Cargando...'"
                            :isProcessing="form.processing" />
                    </form>


                </div>

                <!--
                <p class="mt-10 text-center text-sm text-gray-500">
                    Don't have a Pai account yet?
                    {{ ' ' }}
                    <a :href="route('register')" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Sig in</a>
                </p>
                -->
            </div>
        </div>
    </AuthLayout>
</template>

<style scoped></style>
